.contact {
    height: 30vh;
    background-color: var(--computerDarkBorder);
    border-radius: 25px;
    padding: max(40px);
    color: var(--terminalText);
}

.contactHeader {
    display: flex;
    align-items: center;
    width: 100%;
}

.contactHeader hr {
    flex:1;
    border: 3px dashed var(--terminalText);
    margin: 15px;
    transform: skew(-30deg);
}

.contactContent {
    display: grid;
    padding: 30px;
    gap: 30px;
}

.contactContainer a {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--terminalText);
    text-decoration: none;
}

.contactContainer img {
    width: 110px;
}


.contactContainer div {
    font-family: Righteous,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 3rem;
    text-align: left;
}

@media (max-width:1400px) {

    .contactContent {
        padding: 30px;
        gap: 30px;
    }
    
    .contactContainer a {
        gap: 20px;
    }
    
    .contactContainer img {
        width: 80px;
    }
    
    
    .contactContainer div {
        font-size: 2rem;
    }

}


@media (max-width: 775px) {
    .contact {
        padding: 20px;
    }

    .contactHeader {
        margin-top: 10px;
        display: block;
    }
}

@media (max-width: 400px) {
    .contact {
        padding: 0px;
    }

    .contactContent {
        padding:10px;
        padding-left: 30px;
        padding-right: 20px;
        gap: 24px;
    }
    
    .contactContainer a {
        gap: 20px;
    }
    
    .contactContainer img {
        width: 65px;
    }
    
    
    .contactContainer div {
        font-size: 1.3rem;
    }
}