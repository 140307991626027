.about {
    height: 40vh;
    background-color: var(--computerDarkBorder);
    border-radius: 25px;
    padding: max(40px);
    color: var(--terminalText);
}

.aboutHeader {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.aboutHeader hr {
    flex:1;
    border: 3px dashed var(--terminalText);
    margin: 15px;
    transform: skew(-30deg);
}

/* CONTENT FORMATTING */

.aboutContent > p {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 20px;
    margin-right: 20px;
}

.aboutContent > .mobile {
    display: none;
}

/* STRIPE FORMATTING */

.stripeContainer {
    display: flex;
    column-gap: 5px;
}

.stripeContainer.line {
    flex: 1;
    display: flex;
    column-gap: 0px;
    padding-left: 15px;
}

.stripeContainer.line > .stripe {
    height: 8px;
}

.stripe {
    flex: 1;
    height: 20px;
    transform: skew(-30deg);
}

.stripe.orange {
    background-color: var(--mainOrange);
}
.stripe.redOrange {
    background-color: var(--mainRedOrange);
}
.stripe.navy {
    background-color: var(--mainNavy);
}
.stripe.darkBlue {
    background-color: var(--mainDarkBlue);
}

@media (max-width: 775px) {
    .about {
        padding: 20px;
    }

    .aboutHeader {
        margin-top: 10px;
        display: block;
    }

    .aboutContent > p {
        font-size: 1.3rem;
    }
}

@media (max-width: 400px) {
    .about {
        padding: 0px;
    }

    .aboutContent > p, .aboutContent > br {
        display: none;
    }

    .aboutContent > .mobile.pageOne {
        display: block;
        font-size: 1rem;
    }

}