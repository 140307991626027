* {
    margin: 0px;
    padding: 0px;
}

.titleButton {
    background: transparent;
    border: none;
    font-family: Righteous,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 1.5rem;
}

.titleButton.beforeScroll{
    transition: 400ms;
    color: var(--mainBlack);
}

.titleButton.afterScroll{
    transition: 400ms;
    color: var(--offWhite);
}

.homeButton {
    background-color: transparent;
    width: auto;
    border: none;
}


.navBar {
    position: sticky;
    top: 0px;
    width: 100%;
    height: max(60px);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: var(--mainBlack);
}

.navBar.beforeScroll{
    background-color: transparent;
    transition: 500ms;
    color: var(--mainBlack);
}

.navBar.afterScroll{
    background-color: var(--carpetGreen);
    transition: 500ms;
    color: var(--offWhite);
}

.siteName {
    display: flex;
    gap: max(20px);
    margin-left: max(30px);
}

.mainLogo img {
    display: block;
    width: max(12%,40px);
    margin-left: auto;
    margin-right: auto;
    animation: gear-spin 10s infinite;
}

.navBar.beforeScroll img{
    filter:invert(0%);
    transition: 400ms;
}

.navBar.afterScroll img{
    filter:invert(100%);
    transition: 400ms;
}

@keyframes gear-spin {
    0% {
        transform: rotate(0deg);
    }

    40% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
    
}

.links {
    display: flex;
    gap: max(20px);
    margin-right: max(30px);
}

.links button {
    background-color: transparent;
    border: none;
    font-family: Righteous,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 1.5rem;
}

.links button.afterScroll {
    color: var(--offWhite);
}

@media (max-width:1400px) {
    .links {
        display: none;
    }

    .navBar {
        justify-content: space-between;
    }

    .mainLogo img {
        
        width: max(40px);
        margin-right: 30px;
       
    }
}