@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

.floppy {
    width: 200px;
    transition: 200ms;
}

button.floppy {
    border: none;
}


.floppy {
    background: transparent;
}


.floppy:hover {
    width: max(220px);
}

.floppy .topFill {
    fill: var(--mainWhite);
}

.floppy text {
    font-family: Permanent Marker,'Courier New', Courier, monospace;
    font-size: 70px;
    font-weight: 800;
}

@media (max-width:1600px) {
    .floppy {
        width: 150px;
    }

    .floppy:hover {
        width: max(170px);
    }
}

@media (max-width:700px) {
    .floppy {
        width: 110px;
    }

    .floppy:hover {
        width: max(130px);
    }
}

@media (max-width:400px) {
    .floppy {
        width: 80px;
    }

    .floppy:hover {
        width: max(100px);
    }
}





