:root {
  --mainBlack: black;
  --mainOrange: orange;
  --mainRedOrange: #C43D13;
  --mainNavy: #234C72;
  --mainDarkBlue: #18294A;
  --mainBlue: rgb(94,192,165);
  --mainWhite: white;
  --offWhite: rgb(244,241,232);
  --floppyRed: #ED2939;
  --floppyDarkRed: darkred;
  --floppyYellow:rgb(255,188,50);
  --floppyDarkYellow:rgb(156, 104, 0);
  --floppyBlue:#192bc2;
  --agedComputer: rgb(201,177,118);
  --computerDarkBorder: rgb(33, 32, 32);
  --terminalText: rgb(4, 195, 52);
  --carpetGreen: #012b01;
}

.App {
  text-align: center;
  position: absolute;
  display: grid;
  width: 100vw;
  margin: 0px;
  row-gap: 0px;
  background-color: var(--offWhite);
}

